import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private _api: ApiService,
    private _router: Router,
  ) {

  }
  versaoJWT;
  isRefreshingToken;
  isRefreshing;
  count = 0;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addHeader(req);

    return next.handle(req).pipe(catchError((error: any) => {
      if (error instanceof HttpErrorResponse) {
        if ((error.status === 401 || error.status == 403) && error.url.indexOf('/login') == -1) {
          return next.handle(req).pipe(catchError(this.handleError));
        } else if (error.status != 200) {
          return this.handleError(error)
        }
      }
    }), map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
      return event;
    })) as Observable<HttpEvent<any>>;
  }

  private addHeader(request: HttpRequest<any>) {
    let getEndPoint = request.url.split('/')[request.url.split('/').length - 1];
    return request;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof HttpErrorResponse) {
      console.error(
        `Erro Na request ${error.error.message}`
      )
    } else {
      console.error(error)
      if (error.status == 401 && error.url.indexOf('/login') == -1) {

        ApiService.userNext(null);
        localStorage.clear();
        location.reload();
      }
      console.error(
        `Código do erro ${error.status}, Erro: ${JSON.stringify(error.error)}`
      )
    }
    return throwError(error)
  }
  test;
  staticshowNotificacaoVersao() {
    if (this.test) {
      return;
    }
  }
  toasterClickedHandler() {
    location.reload();
    this.test = []
  }
}
