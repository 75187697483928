import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexChart,
  ApexNonAxisChartSeries,
  ApexOptions,
  ApexResponsive,
  ChartComponent,
} from 'ng-apexcharts';
import { ApiService } from '../service/api.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { Debounce } from '../decorators/debouce-decorator';
import { groupBy } from 'lodash';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';
interface Empresa {
  empresa: string;
  valorTotal: string;
  numVendas: string;
  ticketMedio: string;
}

interface FiltroRequest {
  data_inicio: string;
  data_fim: string;
  limit: boolean;
  usuario_id: string;
  tamanho: string;
  cor: string;
  marca: string;
  genero: string;
  grupo: string;
  subgrupo: string;
  filtro: string;
}
@Component({
  selector: 'app-ranking-produtos',
  templateUrl: './ranking-produtos.component.html',
  styleUrls: ['./ranking-produtos.component.scss'],
})
export class RankingProdutosComponent implements OnInit {
  empresa: any = [];
  date = new Date();
  init_dash = false;

  filtro_request: FiltroRequest = {
    usuario_id: localStorage.getItem('id'),
    data_inicio: this.date.toISOString().substring(0, 10),
    data_fim: this.date.toISOString().substring(0, 10),
    limit: true,
    tamanho: '',
    cor: '',
    marca: '',
    genero: '',
    grupo: '',
    subgrupo: '',
    filtro: 'PRODUTO',
  };
  usuario;
  filtro = {
    dataInicio: this.date,
    dataFim: this.date,
    mesAtual: 0,
    semanaAtual: 0,
    dia: 0,
    selecionado: 'DIA',
  };
  filter_options = [
    { valor: 'PRODUTO', descricao: 'Produto' },
    { valor: 'TAMANHO', descricao: 'Tamanho' },
    { valor: 'COR', descricao: 'Cor' },
    { valor: 'MARCA', descricao: 'Marca' },
    { valor: 'GENERO', descricao: 'Gênero' },
    { valor: 'GRUPO', descricao: 'Grupo' },
    { valor: 'SUBGRUPO', descricao: 'SubGrupo' },
  ];
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    try {
      let u = JSON.parse(localStorage.getItem('uscsw'));
      this.usuario = u;
    } catch (error) {

    }
    this.addMes()
    // this.getEmpresas();
    this.PesquisarDash();


  }
  ionViewWillEnter() {
    try {
      let u = JSON.parse(localStorage.getItem('uscsw'));
      this.usuario = u;
    } catch (error) {

    }
    this.addMes()
    this.PesquisarDash();

    // this.getRanking();
    // this.getEmpresas();
  }
  @Debounce(250)
  async getRanking(event?) {

    this.init_dash = false;
    this.cdr.detectChanges();
    this.filtro_request.usuario_id = localStorage.getItem('id');
    this.filtro_request.data_fim = this.datePipe.transform(
      this.filtro.dataFim,
      'yyyy-MM-dd'
    );
    this.filtro_request.data_inicio = this.datePipe.transform(
      this.filtro.dataInicio,
      'yyyy-MM-dd'
    );
    this.api
      .post('produtos', this.filtro_request)
      .then(async (response: any) => {
        if (response) {
          this.empresa = [];
          this.empresa = response.retorno;
          for (let a = 0; a < this.empresa.length; a++) {
            const element = this.empresa[a];
            let reduce = element.produtos.map((e) => Number(Number(e.quantidade).toFixed(2)));
            let total = reduce.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            );
            this.empresa[a]['valorTotal'] = total;
          }
        }
      })
      .finally(() => {
        setTimeout(() => {
          this.init_dash = true;
          this.cdr.detectChanges();
          event?.target.complete();
        }, 200);
      });
  }

  returnPorcentage(qtd, total) {
    const sum = 100 / total;
    return (sum * qtd).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }

  proximo() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual++;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual++;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia++;
        this.addDia();
        break;
    }
  }

  anterior() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual--;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual--;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia--;
        this.addDia();
        break;
    }
  }

  addSemana() {
    this.filtro.selecionado = 'SEMANA';
    let inicio = moment().add(this.filtro.semanaAtual, 'weeks').day(0); // domingo desta semana
    let fim = moment().add(this.filtro.semanaAtual, 'weeks').day(6); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());
  }
  addMes() {
    this.filtro.selecionado = 'MES';
    let inicio = moment().add(this.filtro.mesAtual, 'month').startOf('month'); // domingo desta semana
    let fim = moment().add(this.filtro.mesAtual, 'month').endOf('month'); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());

  }
  addDia() {
    this.filtro.selecionado = 'DIA';
    let inicio = moment().add(this.filtro.dia, 'day').startOf('day'); // domingo desta semana
    let fim = moment().add(this.filtro.dia, 'day').endOf('day'); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());
  }
  options: any;
  public chartOptions: Partial<ApexOptions> = {
    series: [],
    chart: {
      width: '100%',
      type: 'bar',
      height: 'auto',
      sparkline: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'straight',  show: true,
      width: 1,
      colors: ['#fff']
    },
    plotOptions: {
      bar: {
        horizontal: true,
        // barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'bottom'
            },
        // borderRadius: 4,

      },
    },
    responsive:[
      {
        breakpoint: 500,
        options: {
          chart: {
            height: 800,
            width: '100%',
            sparkline: {
              enabled: true
            },
          },
          legend: {
            width: '100%',
            position: "bottom",
            fontSize: '14px',
            offsetX: -20,
            offsetY: 0,
          },
          subtitle: {
            offsetX: -10,
            // offsetY: 0,
          },
          title: {
            offsetX: -10,
            // offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      type: 'category',

      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
      ],

      // labels:{
      //   show: true,
      //   formatter:  function(value, timestamp, opts) {
      //     return  Number(value).toLocaleString('pt-BR', {
      //       minimumFractionDigits: 2,
      //       style: 'currency',
      //       currency: 'BRL',
      //     })
      //   }
      // }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',

      style: {
        fontSize: '16px',
        fontFamily: 'Roboto, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ['#ffffff'],
      },


    },
    tooltip: {
      enabled: true,
      fixed: {
        position: 'topLeft'
      },
      fillSeriesColor: false,
      theme: 'dark',


    },
    legend: {
      // position: 'bottom',
      show: true,
      height: 300,
      horizontalAlign: 'center',
      fontSize: '14px',
      offsetX: 0,
      itemMargin: {
        horizontal: 0,
        vertical: 0,
      },
      formatter: (seriesName, opts) => {
        const round = (num, places) => {
          return +parseFloat(num).toFixed(places);
        };
        // const class3w = (index) => {
        //   if (index % 2) {
        //     return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2.5">';
        //   } else {
        //     return '<span >';
        //   }
        // }
        // return seriesName + ' TESTE';
        return `${
          opts.w.globals.series[0][opts.seriesIndex].toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }) +
          ' | ' +
          seriesName
        }
                      `;
      },
    },
    title: {
      text: undefined,
      align: 'left',
      margin: 20,
      offsetX: 50,
      offsetY: 0,
      style: {
        fontSize: '25px',
        fontWeight: 'bold',
        fontFamily: 'inherit',
        color: '#303030',
      },
    },
    subtitle: {
      text: undefined,
      // align: 'left',
      margin: 20,
      offsetX: 50,
      // offsetY: 370,
      style: {
        fontSize: '18px',
        fontWeight: 'normal',
        fontFamily: 'inherit',
        color: '#303030',
      },
    },
  };
  areaChart1: GoogleChartInterface = {
    chartType: GoogleChartType.BarChart,
    dataTable: [
      ['Element', 'Density', { role: 'style' }],
      ['Copper', 8.94, '#b87333'],            // RGB value
      ['Silver', 10.49, 'silver'],            // English color name
      ['Gold', 19.30, 'gold'],
      ['Platinum', 21.45, 'color: #e5e4e2' ],
    ],
    options: {
      title: 'Company Performance',
      hAxis: { title: 'Year', titleTextStyle: { color: '#333' } },
      vAxis: { minValue: 0 },
      width: '100%',
      height: 340,
      colors: ["#ff7f83", "#a5a5a5"],
      backgroundColor: 'transparent'
    },
  };
  empresas = [];
  @Debounce(250)
  PesquisarDash(event?){

    if(this.usuario.pro_ativo) {
      this.getEmpresas(event);
    } else {
      this.getRanking(event);
    }
  }
  @Debounce(250)
  async getEmpresas(event?) {
    this.empresas = [];
    this.init_dash = false;

    await this.api
      .post('pesquisarUsuarioEmpresa', {
        idusuario: localStorage.getItem('id'),
      })
      .then(async (response: any) => {
        if (response) {
          let empresas = response.empresas.map((e) => (e = JSON.parse(e)));
          this.empresas = empresas;
          for (let index = 0; index < this.empresas.length; index++) {
            const empresa = this.empresas[index];
            let produtos = await this.api.get('venda-item/listar', {
              empresa_csw: empresa?.idempresa,
              // sort: 'quantidade',
              // adicionalSelect: "SUM(quantidade) as quantidade_grupo",
              // group_by: 'grupo',
              data_inicio: this.datePipe.transform(
                this.filtro.dataInicio,
                'yyyy-MM-dd'
              ),
              data_fim: this.datePipe.transform(this.filtro.dataFim, 'yyyy-MM-dd'),
              direction: 'ASC',
            });
            this.criaDash0(empresa, index, {...produtos});
            this.criaDash00(empresa, index, {...produtos});
            this.criaDash001(empresa, index, {...produtos});
            this.criaDash1(empresa, index, {...produtos});
            this.criaDash2(empresa, index, {...produtos});
            this.criaDash3(empresa, index, {...produtos});
            this.criaDash4(empresa, index, {...produtos});
            this.criaDash5(empresa, index, {...produtos});
            this.criaDash6(empresa, index, {...produtos});
            this.criaDash7(empresa, index, {...produtos});
            this.criaDash8(empresa, index, {...produtos});
          }
        }
      }).finally(() => {
        setTimeout(() => {
          this.init_dash = true;
          this.cdr.detectChanges();
          event?.target.complete();
        }, 200);
      });;
  }
  //10 primeiros por valor total
  async criaDash0(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};
    let DASH_1_GOOGLE = { ...this.areaChart1 };

    let produtos_grupo = groupBy(produtos, 'vendedor_nome');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: key,
        valor_total: produtos_grupo[key]
          .map((e) => Number(e.valor_total))
          .reduce((b, a) => b + a, 0),
      });

    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.valor_total- a.valor_total).slice(0, 10);

    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Valor Total',
          data: DASHFORMATADA.map((e) => Number(e.valor_total)),
        },
      ];

    }
    DASH.dataLabels = {...DASH.dataLabels, formatter:
      function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        })
      }}
    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`)
    };
    DASH.yaxis = {
      ...DASH.yaxis
    };
    // DASH.tooltip = {
    //   ...DASH.tooltip
    // };
    DASH.tooltip = {
      ...DASH.tooltip,
      fixed: {
        position: 'topLeft'
      },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            style: 'currency',
                            currency: 'BRL',
                          })
        }
      }
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Valor total por Vendedor',
    };

    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(e.valor_total))
        .reduce((partialSum, a) => partialSum + a, 0)
        .toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        }),
    };

    this.empresas[index_empresa]['DASH_0'] = DASH;
  }
  //10 primeiros por Ticke Medio
  async criaDash001(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};

    let produtos_grupo = groupBy(produtos, 'vendedor_nome');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: key,
        valor_total: produtos_grupo[key]
          .map((e) => Number(e.valor_total))
          .reduce((b, a) => b + a, 0)/Object.keys(groupBy(produtos_grupo[key], 'venda_id')).length,
      });

    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.valor_total- a.valor_total).slice(0, 10);

    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Ticket Médio',
          data: DASHFORMATADA.map((e) => Number(e.valor_total)),
        },
      ];

    }
    DASH.dataLabels = {...DASH.dataLabels, formatter:
      function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        })
      }}
    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`)
    };
    DASH.yaxis = {
      ...DASH.yaxis
    };
    // DASH.tooltip = {
    //   ...DASH.tooltip
    // };
    DASH.tooltip = {
      ...DASH.tooltip,
      fixed: {
        position: 'topLeft'
      },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            style: 'currency',
                            currency: 'BRL',
                          })
        }
      }
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Ticket Médio de venda total por Vendedor',
    };

    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(e.valor_total))
        .reduce((partialSum, a) => partialSum + a, 0)
        .toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        }),
    };

    this.empresas[index_empresa]['DASH_001'] = DASH;
  }
  //10 vendedor_nome primeiros por quantidade total
  async criaDash00(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};

    let produtos_grupo = groupBy(produtos, 'vendedor_nome');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: key,
        quantidade: Object.keys(groupBy(produtos_grupo[key], 'venda_id')).length
          // .map((e) => Number(Number(e.quantidade).toFixed(2)))
          // .reduce((b, a) => b + a, 0),
      });
    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.quantidade- a.quantidade).slice(0, 10);

    DASH.legend = {...DASH.legend,
      formatter: (seriesName, opts) => {
        return `${
          opts.w.globals.series[0][opts.seriesIndex].toLocaleString('pt-BR', {
            minimumFractionDigits: 2}) +
          ' | ' +
          seriesName
        }
          `;
      },
    }
    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Nr° de venda Total',
          data: DASHFORMATADA.map((e) => Number(Number(e.quantidade))),
        },
      ];
    }
    DASH.tooltip = {
      ...DASH.tooltip,
      // fixed: {
      //   position: 'topLeft'
      // },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })
        }
      }
    };
    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`),
    };
    DASH.yaxis = {
      ...DASH.yaxis,
      labels: {
        maxWidth: 15
      }
    };

    DASH.tooltip = {
      ...DASH.tooltip
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Nr° de venda total por Vendedor',
    };

    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(Number(e.quantidade).toFixed(2)))
        .reduce((partialSum, a) => partialSum + a, 0).toLocaleString('pt-BR', {
          minimumFractionDigits: 2})
        // .toLocaleString('pt-BR', {
        //   minimumFractionDigits: 2,
        //   style: 'currency',
        //   currency: 'BRL',
        // }),
    };

    this.empresas[index_empresa]['DASH_00'] = DASH;
  }
  //10 primeiros por valor total
  async criaDash1(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};
    let DASH_1_GOOGLE = { ...this.areaChart1 };

    let produtos_grupo = groupBy(produtos, 'produto_cod');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: produtos_grupo[key][0].produto_desc,
        valor_total: produtos_grupo[key]
          .map((e) => Number(e.valor_total))
          .reduce((b, a) => b + a, 0),
      });
    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.valor_total- a.valor_total).slice(0, 10);

    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Valor Total',
          data: DASHFORMATADA.map((e) => Number(e.valor_total)),
        },
      ];

    }
    DASH.dataLabels = {...DASH.dataLabels, formatter:
      function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        })
      }}
    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`)
    };
    DASH.yaxis = {
      ...DASH.yaxis
    };
    // DASH.tooltip = {
    //   ...DASH.tooltip
    // };
    DASH.tooltip = {
      ...DASH.tooltip,
      fixed: {
        position: 'topLeft'
      },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            style: 'currency',
                            currency: 'BRL',
                          })
        }
      }
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Valor total por Produto',
    };

    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(e.valor_total))
        .reduce((partialSum, a) => partialSum + a, 0)
        .toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        }),
    };

    this.empresas[index_empresa]['DASH_1_GOOGLE'] = DASH_1_GOOGLE;
    this.empresas[index_empresa]['DASH_2_GOOGLE'] = this.areaChart1;
    this.empresas[index_empresa]['DASH_1'] = DASH;
  }
  //10 primeiros por quantidade total
  async criaDash2(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};

    let produtos_grupo = groupBy(produtos, 'produto_cod');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: produtos_grupo[key][0].produto_desc,
        quantidade: produtos_grupo[key]
          .map((e) => Number(Number(e.quantidade)))
          .reduce((b, a) => b + a, 0).toFixed(2),
      });
    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.quantidade- a.quantidade).slice(0, 10);

    DASH.legend = {...DASH.legend,
      formatter: (seriesName, opts) => {
        return `${
          opts.w.globals.series[0][opts.seriesIndex].toLocaleString('pt-BR', {
            minimumFractionDigits: 2}) +
          ' | ' +
          seriesName
        }
          `;
      },
    }
    DASH.tooltip = {
      ...DASH.tooltip,
      // fixed: {
      //   position: 'topLeft'
      // },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })
        }
      }
    };
    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Quantidade Total',
          data: DASHFORMATADA.map((e) => Number(Number(e.quantidade).toFixed(2))),
        },
      ];
    }

    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`),
    };
    DASH.yaxis = {
      ...DASH.yaxis,
      labels: {
        maxWidth: 15
      }
    };

    DASH.tooltip = {
      ...DASH.tooltip
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Quantidade total por Produto',
    };

    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(Number(e.quantidade)))
        .reduce((partialSum, a) => partialSum + a, 0).toLocaleString('pt-BR', {
          minimumFractionDigits: 2})
        // .toLocaleString('pt-BR', {
        //   minimumFractionDigits: 2,
        //   style: 'currency',
        //   currency: 'BRL',
        // }),
    };
    DASH.dataLabels = {...DASH.dataLabels, formatter:
      function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      }}
    this.empresas[index_empresa]['DASH_2'] = DASH;
  }
  //10 Grupos primeiros por valor total
  async criaDash3(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};
    let DASH_1_GOOGLE = { ...this.areaChart1 };

    let produtos_grupo = groupBy(produtos, 'grupo');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: key,
        valor_total: produtos_grupo[key]
          .map((e) => Number(e.valor_total))
          .reduce((b, a) => b + a, 0),
      });

    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.valor_total- a.valor_total).slice(0, 10);
    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Valor Total',
          data: DASHFORMATADA.map((e) => Number(e.valor_total)),
        },
      ];

    }
    DASH.dataLabels = {...DASH.dataLabels, formatter:
      function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        })
      }}
    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`)
    };
    DASH.yaxis = {
      ...DASH.yaxis,
      labels: {
        maxWidth: 15
      }
    };
    // DASH.tooltip = {
    //   ...DASH.tooltip
    // };
    DASH.tooltip = {
      ...DASH.tooltip,
      fixed: {
        position: 'topLeft'
      },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            style: 'currency',
                            currency: 'BRL',
                          })
        }
      }
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Valor total por Grupo',
    };

    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(e.valor_total))
        .reduce((partialSum, a) => partialSum + a, 0)
        .toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        }),
    };

    this.empresas[index_empresa]['DASH_3'] = DASH;
  }
  //10 primeiros GRUPOS por quantidade total
  async criaDash4(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};


    let produtos_grupo = groupBy(produtos, 'grupo');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: key,
        quantidade: produtos_grupo[key]
          .map((e) => Number(Number(e.quantidade)))
          .reduce((b, a) => b + a, 0).toFixed(2),
      });
    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.quantidade- a.quantidade).slice(0, 10);
    DASH.dataLabels = {...DASH.dataLabels, formatter:
      function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      }}
    DASH.legend = {...DASH.legend,
      formatter: (seriesName, opts) => {
        return `${
          opts.w.globals.series[0][opts.seriesIndex].toLocaleString('pt-BR', {
            minimumFractionDigits: 2}) +
          ' | ' +
          seriesName
        }
          `;
      },
    }
    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Quantidade Total',
          data: DASHFORMATADA.map((e) => Number(Number(e.quantidade).toFixed(2))),
        },
      ];
    }

    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`),
    };
    DASH.yaxis = {
      ...DASH.yaxis,
      labels: {
        maxWidth: 15
      }
    };

    DASH.tooltip = {
      ...DASH.tooltip
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Quantidade total por Grupo',
    };
    DASH.tooltip = {
      ...DASH.tooltip,
      // fixed: {
      //   position: 'topLeft'
      // },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })
        }
      }
    };
    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(Number(e.quantidade).toFixed(2)))
        .reduce((partialSum, a) => partialSum + a, 0).toLocaleString('pt-BR', {
          minimumFractionDigits: 2})
        // .toLocaleString('pt-BR', {
        //   minimumFractionDigits: 2,
        //   style: 'currency',
        //   currency: 'BRL',
        // }),
    };

    this.empresas[index_empresa]['DASH_4'] = DASH;
  }
  //10 Marcas primeiros por valor total
  async criaDash5(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};
    let DASH_1_GOOGLE = { ...this.areaChart1 };
    let produtos_grupo = groupBy(produtos, 'marca');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: key,
        valor_total: produtos_grupo[key]
          .map((e) => Number(e.valor_total))
          .reduce((b, a) => b + a, 0),
      });

    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.valor_total- a.valor_total).slice(0, 10);
    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Valor Total',
          data: DASHFORMATADA.map((e) => Number(e.valor_total)),
        },
      ];

    }
    DASH.dataLabels = {...DASH.dataLabels, formatter:
      function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        })
      }}
    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`)
    };
    DASH.yaxis = {
      ...DASH.yaxis,
      labels: {
        maxWidth: 15
      }
    };
    // DASH.tooltip = {
    //   ...DASH.tooltip
    // };
    DASH.tooltip = {
      ...DASH.tooltip,
      fixed: {
        position: 'topLeft'
      },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            style: 'currency',
                            currency: 'BRL',
                          })
        }
      }
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Valor total por Marca',
    };

    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(e.valor_total))
        .reduce((partialSum, a) => partialSum + a, 0)
        .toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        }),
    };

    this.empresas[index_empresa]['DASH_5'] = DASH;
  }
  //10 Marcas primeiros  por quantidade total
  async criaDash6(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};

    let produtos_grupo = groupBy(produtos, 'marca');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: key,
        quantidade: produtos_grupo[key]
          .map((e) => Number(Number(e.quantidade)))
          .reduce((b, a) => b + a, 0).toFixed(2),
      });
    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.quantidade- a.quantidade).slice(0, 10);
    DASH.legend = {...DASH.legend,
      formatter: (seriesName, opts) => {
        return `${
          opts.w.globals.series[0][opts.seriesIndex].toLocaleString('pt-BR', {
            minimumFractionDigits: 2}) +
          ' | ' +
          seriesName
        }
          `;
      },
    }
    DASH.dataLabels = {...DASH.dataLabels, formatter:
      function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      }}
    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Quantidade Total',
          data: DASHFORMATADA.map((e) => Number(Number(e.quantidade).toFixed(2))),
        },
      ];
    }

    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`),
    };
    DASH.yaxis = {
      ...DASH.yaxis,
      labels: {
        maxWidth: 15
      }
    };

    DASH.tooltip = {
      ...DASH.tooltip
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Quantidade total por Marca',
    };
    DASH.tooltip = {
      ...DASH.tooltip,
      // fixed: {
      //   position: 'topLeft'
      // },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })
        }
      }
    };
    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(Number(e.quantidade)))
        .reduce((partialSum, a) => partialSum + a, 0).toLocaleString('pt-BR', {
          minimumFractionDigits: 2})
        // .toLocaleString('pt-BR', {
        //   minimumFractionDigits: 2,
        //   style: 'currency',
        //   currency: 'BRL',
        // }),
    };

    this.empresas[index_empresa]['DASH_6'] = DASH;
  }
   //10 Cliestes primeiros por valor total
   async criaDash7(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};
    let DASH_1_GOOGLE = { ...this.areaChart1 };
    let produtos_grupo = groupBy(produtos, 'cliente_nome');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: key,
        valor_total: produtos_grupo[key]
          .map((e) => Number(e.valor_total))
          .reduce((b, a) => b + a, 0),
      });

    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.valor_total- a.valor_total).slice(0, 10);
    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Valor Total',
          data: DASHFORMATADA.map((e) => Number(e.valor_total)),
        },
      ];

    }
    DASH.dataLabels = {...DASH.dataLabels, formatter:
      function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        })
      }}
    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`)
    };
    DASH.yaxis = {
      ...DASH.yaxis,
      labels: {
        maxWidth: 15
      }
    };
    // DASH.tooltip = {
    //   ...DASH.tooltip
    // };
    DASH.tooltip = {
      ...DASH.tooltip,
      fixed: {
        position: 'topLeft'
      },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            style: 'currency',
                            currency: 'BRL',
                          })
        }
      }
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Valor total por Cliente',
    };

    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(e.valor_total))
        .reduce((partialSum, a) => partialSum + a, 0)
        .toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        }),
    };

    this.empresas[index_empresa]['DASH_7'] = DASH;
  }
  //10 Cliestes primeiros  por quantidade total
  async criaDash8(empresa, index_empresa, produtos) {
    let DASH = {...this.chartOptions};

    let produtos_grupo = groupBy(produtos, 'cliente_nome');
    let DASHFORMATADA = [];
    let keys = Object.keys(produtos_grupo);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      DASHFORMATADA.push({
        descricao: key,
        quantidade: produtos_grupo[key]
          .map((e) => Number(Number(e.quantidade)))
          .reduce((b, a) => b + a, 0).toFixed(2),
      });
    }
    DASHFORMATADA = DASHFORMATADA.sort((a,b)=> b.quantidade- a.quantidade).slice(0, 10);
    DASH.legend = {...DASH.legend,
      formatter: (seriesName, opts) => {
        return `${
          opts.w.globals.series[0][opts.seriesIndex].toLocaleString('pt-BR', {
            minimumFractionDigits: 2}) +
          ' | ' +
          seriesName
        }
          `;
      },
    }
    if(DASHFORMATADA.length > 0) {
      DASH.series = [
        {
          name: 'Nr° de venda Total',
          data: DASHFORMATADA.map((e) => Number(Number(e.quantidade).toFixed(2))),
        },
      ];
    }
    DASH.dataLabels = {...DASH.dataLabels, formatter:
      function(value, { seriesIndex, dataPointIndex, w }) {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        })
      }}
    DASH.xaxis = {
      ...DASH.xaxis,
      categories: DASHFORMATADA.map((e) => `${String(e.descricao)}`),
    };
    DASH.yaxis = {
      ...DASH.yaxis,
      labels: {
        maxWidth: 15
      }
    };

    DASH.tooltip = {
      ...DASH.tooltip
    };
    DASH.subtitle = {
      ...this.chartOptions.subtitle,
      text: 'Quantidade total de itens por Cliente',
    };
    DASH.tooltip = {
      ...DASH.tooltip,
      // fixed: {
      //   position: 'topLeft'
      // },
      y:{
        formatter(val, opts) {
            return val.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })
        }
      }
    };
    DASH.title = {
      ...this.chartOptions.title,
      text: DASHFORMATADA.map((e) => Number(Number(e.quantidade).toFixed(2)))
        .reduce((partialSum, a) => partialSum + a, 0).toLocaleString('pt-BR', {
          minimumFractionDigits: 2})
        // .toLocaleString('pt-BR', {
        //   minimumFractionDigits: 2,
        //   style: 'currency',
        //   currency: 'BRL',
        // }),
    };

    this.empresas[index_empresa]['DASH_8'] = DASH;
  }
}
