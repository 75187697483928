import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { FuseAlertType } from '../components/alert/alert.types';
import { fuseAnimations } from '../components/animations';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class LostPasswordComponent implements OnInit {
  lostPasswordForm: FormGroup;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  showAlert: boolean = false;
  is_app = false;
  constructor(
    private api: ApiService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    public platform: Platform) {
    this.lostPasswordForm = this._formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngOnInit() {
    if ((this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
      this.is_app = true
    } else {
      this.is_app = false
    }
  }
  success = false;
  signIn(): void {
    if (this.lostPasswordForm.invalid) {
      return;
    }

    this.lostPasswordForm.disable();

    this.showAlert = false;

    this.api.post('resetSenha/cadastrar', this.lostPasswordForm.value)
      .then(
        (response: any) => {
          if (response.status) {
            this.success = true;
            this.alert = {
              type: 'success',
              message: "Verifique seu e-mail para redefinir sua senha."
            };
            this.showAlert = true;
          }
        }
      ).catch(e => {
        console.log(e)
        this.lostPasswordForm.enable();
        this.alert = {
          type: 'error',
          message: "Houve um Erro: " + e.error.message
        };
        this.showAlert = true;
        this.cdr.detectChanges();
      });
    this.cdr.detectChanges();
  }
}
