import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'negativeNumber',
})
export class NegativeNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {

    if (value?.anterior > value?.atual) {
      return true;
    } else {
      return false;
    }
  }
}
