export const snakeCase = (str) => {
    return camelCase(str)
        // ABc -> a_bc
        .replace(/([A-Z])([A-Z])([a-z])/g, "$1_$2$3")
        // aC -> a_c
        .replace(/([a-z0-9])([A-Z])/g, "$1_$2")
        .toLowerCase();
}
export const camelCase = (str) => {
    return str
    .toLowerCase()
    .replace(/[^\w]+(.)/g, (ltr) => ltr.toUpperCase())
    .replace(/[^a-zA-Z]/g, '')
}
export const convertBlobToBase64 = (blob: Blob) : Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.readAsDataURL(blob);
    });

export function isWindows(): boolean {
  if (!window || !navigator) {
    return false;
  }

  const a: string = navigator.userAgent || navigator.vendor || (window as any).opera;

  return /windows/i.test(a);
}
export const formarDRE = (dados)=> {
  let newData = [];
  for (let index = 0; index < dados.length; index++) {
    const element = dados[index];
    if(!element.descricao) {
      element.descricao = "Sem Plano de Contas"
    }
    if(String(element.descricao).toLocaleUpperCase().includes("CMV")) {

      let existe_index = newData.findIndex(e => e.descricao == "CMV");
      if(existe_index != -1 ) {
        newData[existe_index].valor = +newData[existe_index].valor + +element.valor
      } else {
        newData.push({
          descricao: "CMV",
          valor: element.valor,
          dre_id: element.dre_id,
          tipo: element.tipo,
          csw_id: element.csw_id,
          layout: element.layout,
          data: element.data,

        })
      }

    } else {
      newData.push(element)
    }
  }
  return newData
}
