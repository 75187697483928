import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'calcEchangeRate'
})
export class CalcExchangeRatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value?.anterior > value?.atual) {

            return (((value.atual - value.anterior) / value.anterior * 100).toLocaleString('fullwide', { maximumFractionDigits: 2 }) + "%");
        } else if(value?.anterior < value?.atual) {
            if(value?.anterior == 0 || value?.anterior == '0'){
                return "0%";
            }else{
                return '+' + (((value?.atual - value?.anterior) / value?.anterior * 100).toLocaleString('fullwide', { maximumFractionDigits: 2 }) + "%");
            }
        }else if(value?.anterior == 0 || value?.anterior == '0' && value?.atual == 0 || value?.atual == '0') {
            return "0%";
        } else {
          return "0%";
        }
    }
}
