import { AbstractControl, FormGroup } from "@angular/forms";

export function passwordConfirme(frm: FormGroup) {
    if (frm.controls['senha'].value === frm.controls['confirme_senha'].value) {
        frm.controls['confirme_senha'].setErrors(null);
        return null
    } else {
        frm.controls['confirme_senha'].setErrors({'mismatch': true});

        return {'mismatch': true}
    }
  }