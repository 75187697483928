import { DomController } from '@ionic/angular';
import { Directive, Renderer2, Input, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appFadeHeader]'
})
export class FadeHeaderDirective implements OnInit {

  @Input('appFadeHeader') toolbar: any;
  private toolbarHeight = 44;
  constructor(private domCtrl: DomController) {

  }

  ngOnInit(): void {
    this.toolbar = this.toolbar.el;
  }

  // @HostListener('ionScroll', ['$event']) onContentScroll($event) {
  //   let scrollTop = $event.detail.scrollTop;
  //   if (scrollTop >= 255) {
  //     scrollTop = 255;
  //   }
  //   const hexDist = scrollTop.toString(16);
  //   this.domCtrl.write(() => {
  //     this.toolbar.style.setProperty('--background', `#f4f5f8${hexDist}`);
  //   })

  // }
  @HostListener('ionScroll', ['$event']) onContentScroll($event) {
    let scrollTop2 = 350 - $event.detail.scrollTop;
    if (scrollTop2 <= 9) {
      scrollTop2 = 9;
    }
    this.domCtrl.write(() => {
      this.toolbar.style.setProperty('z-index', (scrollTop2));
    });
  }
}
