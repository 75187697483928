import { AuthGuardAdm } from './service/auth-guard-adm.service';
import { AuthGuardCliente } from './service/auth-guard-cliente.service';
import { AuthGuard } from './service/auth-guard.service';
import { TabsComponent } from './tabs/tabs.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NoAuthGuard } from './service/no-auth-guard.service';
import { GeralComponent } from './geral/geral.component';
import { FormaPagamentoComponent } from './forma-pagamento/forma-pagamento.component';
import { PrazoPagamentoComponent } from './prazo-pagamento/prazo-pagamento.component';
import { RankingProdutosComponent } from './ranking-produtos/ranking-produtos.component';
import { EmpresaComponent } from './adm/empresa/empresa.component';
import { UsuarioComponent } from './adm/usuario/usuario.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { DivinaProdutosMaisVendidosComponent } from './divina-terra/divina-produto-mais-vendidos/divina-produto-mais-vendidos.component';
import { DivinaProdutosPorEmpresaComponent } from './divina-terra/divina-produtos-por-empresa/divina-produtos-por-empresa.component';
import { DREComponent } from './dre/dre.component';
import { HistoricoComponent } from './historico/historico.component';
import {  DREFaturamentoComponent } from './dre-faturamento/dre-faturamento.component';
import { DREV2Component } from './dre-v2/dre-v2.component';
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'lost/password',
    component: LostPasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'forget/password',
    component: ForgetPasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'forget/password/:token',
    component: ForgetPasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: '',
    redirectTo: 'geral',
    pathMatch: 'full'
  },
  {
    path: '*',
    redirectTo: 'geral',
    // pathMatch: 'full'
  },
  {
    path: '',
    component: TabsComponent,
    canActivate: [AuthGuard, AuthGuardCliente],
    children: [
      {
        path: 'geral',
        component: GeralComponent
      },
      {
        path: 'alterar-senha',
        component: AlterarSenhaComponent
      },
      {
        path: 'forma-pagamento',
        component: FormaPagamentoComponent
      },
      {
        path: 'prazo-pagamento',
        component: PrazoPagamentoComponent
      },
      {
        path: 'ranking-produtos',
        component: RankingProdutosComponent
      },
      {
        path: 'divina-produto-mais-vendidos',
        component: DivinaProdutosMaisVendidosComponent
      },
      {
        path: 'divina-produtos-por-empresa',
        component: DivinaProdutosPorEmpresaComponent
      },
      {
        path: 'dre',
        children: [
          {
            path: 'faturamento',
            component: DREFaturamentoComponent
          },
          {
            path: 'efetivo',
            component: DREComponent
          },
          {
            path: 'V2',
            component: DREV2Component
          },
        ]
      },

      {
        path: 'historico',
        component: HistoricoComponent
      },
      // {
      //   path: ':id',
      //   loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule)
      // },


    ]
  },
  {
    path: 'adm',
    component: TabsComponent,
    canActivate: [AuthGuard, AuthGuardAdm],
    children: [
      {
        path: 'empresa',
        component: EmpresaComponent
      },
      {
        path: 'usuario',
        component: UsuarioComponent
      }

    ]
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
