import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { FuseAlertType } from '../components/alert/alert.types';
import { fuseAnimations } from '../components/animations';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  signInForm: FormGroup;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  showAlert: boolean = false;
  is_app = false;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private api: ApiService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    public platform: Platform) {
    this.signInForm = this._formBuilder.group({
      usuario: ['', Validators.required],
      senha: ['', Validators.required],
    });
  }

  ngOnInit() {
    if ((this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
      this.is_app = true
    } else {
      this.is_app = false
    }
  }

  signIn(): void {
    if (this.signInForm.invalid) {
      return;
    }

    this.signInForm.disable();

    this.showAlert = false;

    this.api.login(this.signInForm.value)
      .then(
        (response: any) => {
          if (response.login == 'True') {

            localStorage.setItem('id', response.usuario[0].idusuario)
            const {senha, ...usuario} = response.usuario[0];
            localStorage.setItem('uscsw', JSON.stringify(usuario))
            console.log(usuario)
            localStorage.setItem('sessionId', response.login)
            localStorage.setItem('idDesenvolvedor', usuario.iddesenvolvedor)
            this.signInForm.reset();
            this.signInForm.enable();
            if (usuario.desenvolvedor == 'true') {
              // window.location.assign('https://csw.krafti.com.br');
              localStorage.setItem('desenvolvedor', usuario.desenvolvedor);
              this._router.navigate(['adm/empresa'], { replaceUrl: true });
            } else {
              this._router.navigate(['geral'], { replaceUrl: true });
            }

          } else {
            this.signInForm.enable();
            this.signInForm.reset();
            this.alert = {
              type: 'error',
              message: 'Email ou senha errados'
            };
            this.showAlert = true;
            this.cdr.detectChanges();
          }
        }
      ).catch(e => {
        console.log(e)
        this.signInForm.enable();
        this.alert = {
          type: 'error',
          message: "Houve um Erro: "+ e.message
        };
        this.showAlert = true;
        this.cdr.detectChanges();
      });
    this.cdr.detectChanges();
  }
}
