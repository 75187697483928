import { ApiService } from './../../service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
export interface DialogData {
  descricao
}
@Component({
  templateUrl: './add-empresa-dialog.component.html',
  styleUrls: ['./add-empresa-dialog.component.scss']
})

export class AddEmpresaDialogComponent implements OnInit {
  addEmpresaForm: FormGroup;
  _data;
  sub;
  ambiente;
  editar = false;
  result = { value: false, action: '' };
  constructor(
    public dialogRef: MatDialogRef<AddEmpresaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _formBuilder: FormBuilder,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
  ) {
    this._data = data;
    this.addEmpresaForm = this._formBuilder.group({
      empresa: ['', Validators.required],
      ativo: [false],
      idempresa: [''],
    });
  }

  ngOnInit(): void {
    if (this._data.empresa_nome) {
      this.editar = true;
      this.addEmpresaForm.controls.empresa.setValue(this._data.empresa_nome);
      this.addEmpresaForm.controls.ativo.setValue(this._data.empresa_ativa);
      this.addEmpresaForm.controls.idempresa.setValue(this._data.empresa_id);
    } else {
      this.editar = false;
    }
  }
  ionViewWillEnter() {
    if (this._data.empresa_nome) {
      this.editar = true;
      this.addEmpresaForm.controls.empresa.setValue(this._data.empresa_nome);
      this.addEmpresaForm.controls.ativo.setValue(this._data.empresa_ativa);
      this.addEmpresaForm.controls.idempresa.setValue(this._data.empresa_id);
    } else {
      this.editar = false;
    }
  }
  close(): void {
    this.dialogRef.close(false);
  }
  async salvar() {
    await this.addEmpresa();
    await this.dialogRef.close(this.result);
  }

  async addEmpresa() {
    const controls = this.addEmpresaForm.controls;
    if (this.addEmpresaForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.addEmpresaForm.disable();
    let empresa: any = {
      empresa: '',
      ativo: '',
      idempresa: '',
    };
    Object.keys(controls).forEach(controlName =>
      empresa[controlName] = controls[controlName].value
    );
    if (empresa.idempresa) {
      await this.api.post('editarEmpresa', empresa).then(
        async (response: any) => {
          if (response.retorno == "true") {
            this.result.value = true;
            this.result.action = 'editar';
          } else {
            this.result.value = false;
            this.result.action = 'editar';
          }
        }
      ).finally(() => {
        this.cdr.detectChanges();
      });
    } else {
      delete empresa.idempresa;
      empresa.iddesenvolvedor = localStorage.getItem('id');
      await this.api.post('addEmpresa', empresa).then(
        async (response: any) => {
          if (response.retorno == "true") {
            this.result.value = true;
            this.result.action = 'adicionar';
          } else {
            this.result.value = false;
            this.result.action = 'adicionar';
          }
        }
      ).finally(() => {
        this.cdr.detectChanges();
      });
    }
  }
}
