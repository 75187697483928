import { NavigationEnd, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnDestroy {
  mostrar = true;
  sub;
  adm = false;
  usuario;
  constructor(private router: Router, private crd: ChangeDetectorRef, private api: ApiService) {
    if (localStorage.getItem('desenvolvedor') == 'true') {
      this.adm = true;
    } else {
      this.adm = false;
    }
    this.usuario = JSON.parse(localStorage.getItem('uscsw'));
    if(localStorage.getItem('id')) {
      this.api.get('usuario/'+ localStorage.getItem('id')).then(
        async (response: any) => {
          if (response) {
            this.usuario = response;
            localStorage.setItem('uscsw', JSON.stringify(response));
            // this.usuarios = [];
            // this.usuarios = response.usuarios;
            // let usuario_nome = response.usuario;

          }
        }
      ).finally(() => {
      });
    }
  }
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
  ionViewWillEnter() {}
  logout() {
    localStorage.clear();
    this.router.navigate(['login'], { replaceUrl: true });
  }
  ionViewWillLeave() {
    if (this.sub) this.sub.unsubscribe();
    // if (this.subscription2) this.subscription2.unsubscribe();
    // if (this.subscription3) this.subscription3.unsubscribe();
    // if (this.subscription4) this.subscription4.unsubscribe();
  }

  validarAcesso(url) {
    this.usuario = JSON.parse(localStorage.getItem('uscsw'));
    if (!this.usuario?.acessos || this.usuario?.acessos.length == 0) {
      return false
    }
    return this.usuario.acessos.findIndex((e) => e.url == url && e.exibir==true) >= 0;

  }
}
