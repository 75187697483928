import { ApiService } from '../../service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import { FuseAlertType } from '../alert';
import { fuseAnimations } from '../animations';
export interface DialogData {
  descricao;
}
interface FiltroRequestEmpresa {
  iddesenvolvedor: string;
}
@Component({
  templateUrl: './email-usuario-dialog.component.html',
  styleUrls: ['./email-usuario-dialog.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class EmailUsuarioDialogComponent implements OnInit {
  addUsuarioForm: FormGroup;
  _data;
  sub;
  ambiente;
  editar = false;
  result = { value: false, action: '' };
  empresas = [];
  empresas_vinculadas = [];
  filtro_request_empresa: FiltroRequestEmpresa = {
    iddesenvolvedor: localStorage.getItem('idDesenvolvedor'),
  };
  editar_empresas = false;
  old_senha = '';
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: '',
  };
  showAlert: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<EmailUsuarioDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _formBuilder: FormBuilder,
    private api: ApiService,
    private cdr: ChangeDetectorRef
  ) {
    this._data = data;
    this.addUsuarioForm = this._formBuilder.group({
      idusuario: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  async ngOnInit() {
    await this.formatForm();
    this.addUsuarioForm.controls.email.valueChanges.subscribe((e) => {
      console.log(e);
      let valor = this.addUsuarioForm.controls['email'].value;
      if (/\s+/g.test(valor)) {
        this.addUsuarioForm.controls['email'].setValue(
          valor.replace(/\s+/g, '')
        );
        this.addUsuarioForm.controls['email'].updateValueAndValidity({
          emitEvent: false,
          onlySelf: true,
        });
      }
    });
    this.getEmpresas();
  }
  async ionViewWillEnter() {
    await this.formatForm();
    this.getEmpresas();
  }
  async formatForm() {
    if (this._data.usuario) {
      this.editar = true;
      await this.addUsuarioForm.controls.idusuario.setValue(
        this._data.idusuario
      );
      await this.addUsuarioForm.controls.email.setValue(this._data.email);
      this.addUsuarioForm.valid;
    } else {
      this.editar = false;
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

  async salvar() {
    this.showAlert = false;
    this.cdr.detectChanges();
    await this.addEmail();
    // await this.dialogRef.close(this.result);
  }

  async addEmail() {
    const controls = this.addUsuarioForm.controls;
    if (this.addUsuarioForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      this.alert = {
        type: 'error',
        message: 'Digite um e-mail válido',
      };
      this.showAlert = true;
      return;
    }
    this.addUsuarioForm.disable();
    let usuario: any = {
      idusuario: '',
      email: '',
    };
    Object.keys(controls).forEach(
      (controlName) => (usuario[controlName] = controls[controlName].value)
    );
    await this.api
      .post('adicionarEmail', usuario)
      .then(async (response: any) => {
        if (response.retorno == 'true') {
          this.dialogRef.close(true);
        } else {
          this.alert = {
            type: 'error',
            message: response.erro,
          };
          this.showAlert = true;
        }
      })
      .catch((erro) => {
        console.log(erro);
        this.alert = {
          type: 'error',
          message: erro.error.message,
        };
        this.showAlert = true;
      })
      .finally(() => {
        this.addUsuarioForm.enable();

        this.cdr.detectChanges();
      });
  }

  getEmpresas() {
    this.api
      .post('pesquisarEmpresa', this.filtro_request_empresa)
      .then(async (response: any) => {
        if (response) {
          this.empresas = [];
          this.empresas = response.empresas;
        }
      })
      .finally(() => {
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 200);
      });
  }
}
