import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})

@Injectable({ providedIn: 'root' })
export class ApiService {
  static user: BehaviorSubject<any> = new BehaviorSubject(
    localStorage.getItem('id')
  );
  encryptSecretKey = 'INFO#$%info345';

  private url: string = environment.apiUrl;
  private urlKrafti: string = environment.urlKrafti;

  public constructor(
    public http: HttpClient,
    public _router: Router,
    public httpClient: HttpClient,
    public back: HttpBackend,
  ) {
    this.httpClient = new HttpClient(back);
    ApiService.userNext(localStorage.getItem('id'));
  }

  static userOn() {
    return this.user.asObservable();
  }

  static userNext(valor) {
    return this.user.next(valor);
  }

  static userOB() {
    return this.user;
  }

  login(auth) {
    return this.http
      .post(environment.apiUrl + 'login', auth)
      .toPromise();
  }

  registrar(usuario) {
    return this.http
      .post(environment.apiUrl + 'cadastrar', usuario)
      .toPromise();
  }

  alterar(usuario) {
    return this.http
      .post(environment.apiUrl + 'alterar', usuario)
      .toPromise();
  }

  logout() {
    ApiService.user.next(null);
    let user = localStorage.getItem('id');
    localStorage.clear();
    this._router.navigate(['/login']);
  }
  public get<T = any>(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }

    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        if (params[k] !== null && params[k] !== undefined) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }

    return this.http.get(this.url + endpoint, reqOpts).toPromise() as unknown as Promise<T>;
  }
  public getSub<T = any>(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }

    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        if (params[k] !== null && params[k] !== undefined) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }

    return this.http.get(this.url + endpoint, reqOpts) as unknown as Observable<T>;
  }
  public getKrafti<T>(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }

    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        if (params[k] !== null && params[k] !== undefined) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }

    return this.http.get(this.urlKrafti + endpoint, reqOpts).toPromise() as unknown as Promise<T>;
  }

  public getUpload(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }

    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    return this.http
      .get(environment.uploadUrl +  endpoint, reqOpts)
      .toPromise();
  }

  public post<T =any>(endpoint: string, body: any, reqOpts?: any) {
    return this.http
      .post(this.url + endpoint, body, reqOpts)
      .toPromise() as unknown as Promise<T>;
  }
  public postKrafti<T =any>(endpoint: string, body: any, reqOpts?: any) {
    return this.http
      .post<T>(this.urlKrafti + endpoint, body, reqOpts)
      .toPromise() as unknown as Promise<T>;
  }
  postEncr(endpoint: string, body: any, reqOpts?: any) {
    return this.http
      .post(
        this.url + '/' + endpoint,
        { cryp: this.encryptData(body) },
        reqOpts
      )
      .toPromise();
  }

  public postUpload(endpoint: string, body: any, reqOpts?: any) {
    return this.http
      .post(environment.uploadUrl + '/' + endpoint, body, reqOpts)
      .toPromise();
  }

  public put(endpoint: string, body: any, reqOpts?: any) {
    return this.http
      .put(this.url + endpoint, body, reqOpts)
      .toPromise();
  }

  public delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.url + '/' + endpoint, reqOpts).toPromise();
  }

  public patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http
      .patch(this.url + '/' + endpoint, body, reqOpts)
      .toPromise();
  }

  public upload(url: string, body: any, reqOpts?: any) {
    return this.http.post(url, body, reqOpts).toPromise();
  }

  public customGet(endpoint: string) {
    return this.http.get(endpoint).toPromise();
  }
  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.encryptSecretKey
      ).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
  public getAddress(zipcode) {
    return this.http.get(`https://viacep.com.br/ws/${zipcode}/json/`);
  }
}
