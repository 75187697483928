import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardAdm implements CanActivate {
  user;
  constructor(private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('id') && localStorage.getItem('sessionId') && localStorage.getItem('desenvolvedor') == 'true') {
      return true;
    } else if (localStorage.getItem('id') && localStorage.getItem('sessionId') && !localStorage.getItem('desenvolvedor')) {
      this.router.navigate(['geral']);
      return false;
    } else {
      // this.router.navigate(['adm/empresa']);
      return false;
    }
  }
}
