import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TabsComponent } from './tabs/tabs.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GeralComponent } from './geral/geral.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import localePt from '@angular/common/locales/pt';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import * as ApexCharts from 'apexcharts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormaPagamentoComponent } from './forma-pagamento/forma-pagamento.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PrazoPagamentoComponent } from './prazo-pagamento/prazo-pagamento.component';
import { RankingProdutosComponent } from './ranking-produtos/ranking-produtos.component';
import { MatTableModule } from '@angular/material/table';
import { FuseAlertModule } from './components/alert/alert.module';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EmpresaComponent } from './adm/empresa/empresa.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SuccessDialogComponent } from './components/success-dialog/success-dialog.component';
import { AddEmpresaDialogComponent } from './components/add-empresa-dialog/add-empresa-dialog.component';
import { UsuarioComponent } from './adm/usuario/usuario.component';
import { AddUsuarioDialogComponent } from './components/add-usuario-dialog/add-usuario-dialog.component';
import { MatListModule } from '@angular/material/list';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { ApiInterceptor } from './service/api.interceptor';
import { EmailUsuarioDialogComponent } from './components/email-usuario-dialog/email-usuario-dialog.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { DivinaProdutosMaisVendidosComponent } from './divina-terra/divina-produto-mais-vendidos/divina-produto-mais-vendidos.component';
import { DivinaProdutosPorEmpresaComponent } from './divina-terra/divina-produtos-por-empresa/divina-produtos-por-empresa.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { DREComponent } from './dre/dre.component';
import { DrePipe } from './dre/dre.pipe';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CalcExchangeRatePipe } from './pipes/calc-exchange-rate.pipe';
import { NegativeNumberPipe } from './pipes/negative-number.pipe';
import { HistoricoComponent } from './historico/historico.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import { DREFaturamentoComponent } from './dre-faturamento/dre-faturamento.component';
import { DREV2Component } from './dre-v2/dre-v2.component';

registerLocaleData(localePt, 'pt-BR');
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TabsComponent,
    GeralComponent,
    FormaPagamentoComponent,
    PrazoPagamentoComponent,
    RankingProdutosComponent,
    EmpresaComponent,
    SuccessDialogComponent,
    AddEmpresaDialogComponent,
    AddUsuarioDialogComponent,
    UsuarioComponent,
    AlterarSenhaComponent,
    EmailUsuarioDialogComponent,
    LostPasswordComponent,
    ForgetPasswordComponent,
    DivinaProdutosMaisVendidosComponent,
    DivinaProdutosPorEmpresaComponent,
    DREComponent,
    DrePipe,
    CalcExchangeRatePipe,
    NegativeNumberPipe,
    HistoricoComponent,
    DREFaturamentoComponent,
    DREV2Component,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // GoogleChartsModule,
    NgxChartsModule,
    NgApexchartsModule,
    MatExpansionModule,
    MatTableModule,
    FuseAlertModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatListModule,
    SharedDirectivesModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatMomentDateModule,
    Ng2GoogleChartsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
    // FuseConfigModule.forRoot(appConfig)
  ],
  exports: [
    SuccessDialogComponent,
    AddEmpresaDialogComponent,
    AddUsuarioDialogComponent,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    MatDatepickerModule,
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
