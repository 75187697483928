import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
export interface DialogData {
  descricao
}
@Component({
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})

export class SuccessDialogComponent implements OnInit {
  _data;
  sub;
  ambiente;
  constructor(
    public dialogRef: MatDialogRef<SuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this._data = data;
  }

  ngOnInit(): void {
  }
  close(): void {
    this.dialogRef.close(false);
  }
  salvar(): void {
    // if (this.data.descricao) {
    //   this.dialogRef.close(this.data);
    // } else {
    this.dialogRef.close(true);
    // }
  }
  colorBasedOnBg = (bgColor, lightColor, darkColor) => {
    var color = (bgColor?.charAt(0) === '#') ? bgColor?.substring(1, 7) : bgColor;
    var r = parseInt(color?.substring(0, 2), 16); // hexToR
    var g = parseInt(color?.substring(2, 4), 16); // hexToG
    var b = parseInt(color?.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? darkColor : lightColor;
  }
}
