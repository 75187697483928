import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
  user;
  constructor(private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!localStorage.getItem('id') || !localStorage.getItem('sessionId')) {
      return true;
    } else {
      this.router.navigate(['']);
      return;
    }
  }
}
