import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({ name: 'drePipe' })
export class DrePipe implements PipeTransform {
  transform(value){
    // return new Observable((observer) => {
      var groups = {};
      // console.log(value);
      value.forEach(function (o) {
        console.log(o);
        // let date = new Date(o.MessageSentAt);
        var group = o.tipo
        // console.log(group);
        groups[group] = groups[group]
          ? groups[group]
          : { grupo_nome: group, array: [], valor_total: 0, total_itens: 0 };
        groups[group].array.push(o);
      });

      // observer.next(
        return Object.keys(groups).map(function (key) {
           let valor_total = groups[key].array.map( e => e.valor).reduce( (a,b)=> a+b,0 );

           groups[key].valor_total = valor_total;
           groups[key].total_itens = groups[key].array.length;
          return groups[key];
        })
      // );
    // });
  }
  // @Pipe({ name: 'groups' })
  // export class GroupsPipe implements PipeTransform {
  //   transform(value, args: string[]): any {
  //     var groups = {};
  //     console.log(value);
  //     value.forEach(function(o) {
  //       console.log(o);
  //       let date = new Date(o.MessageSentAt);
  //       var group =
  //         date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear();
  //       console.log(group);
  //       groups[group] = groups[group]
  //         ? groups[group]
  //         : { name: group, messages: [] };
  //       groups[group].messages.push(o);
  //     });

  //     return Object.keys(groups).map(function(key) {
  //       return groups[key];
  //     });
  //   }
}
