import { DOCUMENT } from '@angular/common';
import { Directive, Input, Renderer2, OnInit, HostListener, Inject } from '@angular/core';
import { DomController } from '@ionic/angular';
import { $ } from 'protractor';

@Directive({
  selector: '[appHideHeader]'
})
export class HideHeaderDirective implements OnInit {

  @Input('appHideHeader') toolbar: any;
  private toolbarHeight = 44;
  constructor(private renderer: Renderer2, private domCtrl: DomController,
    @Inject(DOCUMENT) private _document: Document,) {

  }

  ngOnInit(): void {
    this.toolbar = this.toolbar.el;
    this.domCtrl.read(() => {
      this.toolbarHeight = this.toolbar.clientHeight;
    });
  }

  @HostListener('ionScroll', ['$event']) onContentScroll($event) {
    const scrollTop = $event.detail.scrollTop;
    let newPosition = + (scrollTop / 10);

    if (newPosition > (+this.toolbar.clientHeight - 2)) {
      newPosition = (+this.toolbar.clientHeight - 2);
    }

    let newOpacity = 0 + (newPosition / (+ this.toolbar.clientHeight - 2));

    this.domCtrl.write(() => {
      this.renderer.setStyle(this.toolbar, 'top', `${newPosition}px`);
      this.renderer.setStyle(this.toolbar, 'opacity', newOpacity);
    });
    let scrollTop2 = 255 - $event.detail.scrollTop;
    if (scrollTop2 <= 0) {
      scrollTop2 = 0;
    }
    let hexDist = scrollTop2.toString(16);

  }

}
