import { ApexOptions } from 'ng-apexcharts';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/service/api.service';
import { Platform, ViewWillEnter } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { Moment } from 'moment';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { Debounce } from '../decorators/debouce-decorator';

interface ProdutoRequest {
  codigo: string;
  descricao: string;
  categoria_descricao: string;
  quantidade_venda: number;
  custo_unitario: number;
  valor_venda_unitaria: string;
  total_custo: number;
  total_venda: number;
}

interface FiltroRequest {
  data_inicio: string;
  data_fim: string;
  qtde_registros: number;
  usuario_id: string;
  totalizar_por: 'vlr' | 'qtd';
  uf: string;
}
interface UFResponse {
  result: { uf: string }[];
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class HistoricoComponent implements OnInit, ViewWillEnter {
  empresa: any = [];
  init_dash = true;
  endpoint = 'gen/rest/TSMSincronizar/';
  date = new FormControl(moment());
  filter_options = [5, 10, 15, 20, 30, 50, 100];
  json_data = [
    {
      tipo: 'Receita',
      descricao: 'VENDA CUPOM-PDV',
      valor: 50,
    },
    {
      tipo: 'Despesa',
      descricao: 'DESPESA COM PESSOAL',
      valor: 15,
    },
    {
      tipo: 'Despesa',
      descricao: 'DESPESA COMERCIAIS',
      valor: 20,
    },
  ];

  dataArray = _.groupBy(this.json_data, 'tipo');
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    public _snackBar: MatSnackBar,
    public platform: Platform
  ) {


  }

  ngOnInit() {
    this.getEmpresas();
  }
  ionViewWillEnter() {
    this.getEmpresas();

    // this.();
    // this.getTodas();
  }
  resultado: { uf: string; produtos_valor_total: any[]; produtos_qtd_total }[] =
    [];
  number_dia = 0
  proximo() {
    // this.number_dia++;
    // let date = moment(this.date.value);

    // this.date.setValue(date.add(+1, 'day'));
    this.date.setValue((this.date.value.add(1,'month')))
    this.getEmpresas();
  }

  anterior() {
    this.number_dia--;
    // let date = moment(this.date.value);

    // this.date.setValue(date.add(-1, 'day'));
    this.date.setValue((this.date.value.add(-1,'month')));

    this.getEmpresas();
  }

  // addDia() {
  //   let inicio = moment().add(this.number_dia, 'day').startOf('day'); // domingo desta semana
  //   let fim = moment().add(this.number_dia, 'day').endOf('day'); // sábado desta semana
  //   this.filtro.dataInicio = new Date(inicio.toString());
  //   this.filtro.dataFim = new Date(fim.toString());
  //   let inicio_anterior = moment().add(this.number_dia - 1, 'day').startOf('day'); // domingo desta semana
  //   let fim_anterior = moment().add(this.number_dia - 1, 'day').endOf('day'); // sábado desta semana
  //   this.filtro.dataInicio_anterior = new Date(inicio_anterior.toString());
  //   this.filtro.dataFim_anterior = new Date(fim_anterior.toString());
  //   console.log(this.filtro)
  //   console.log(this.filtro_request)
  // }

  empresas = [];
  public chartOptions: Partial<ApexOptions> = {
    series: [
    ],
    chart: {
      width: '100%',
      type: 'area',
      height: 300,
      // sparkline: {
      //   enabled: false
      // },

    },
    stroke: {
      curve: 'straight'
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    yaxis: {

      labels:{
        show: true,
        formatter(val, opts) {
            return  Number(val).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            })
        },
      }
    },
    xaxis: {
      type: 'category',

      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
      ],
    },
    dataLabels: {
      formatter:
 (value, { seriesIndex, dataPointIndex, w }) => {
  // return w.config.series[seriesIndex].name + ": " + value
  return  value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  })
},
      style: {
        fontSize: '14px',
      },
    },
    responsive: [
      {
        breakpoint: 5000,
        options: {
          plotOptions: {
            pie: {
              offsetX: 0,
            },
          },
          dataLabels: {
            formatter:
       (value, { seriesIndex, dataPointIndex, w }) => {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        })
      },
            style: {
              fontSize: '14px',
            },
          },
          legend: {
            offsetX: 0,
          },
          chart: {
            // height: 300,
            width: '100%',
            // sparkline: {
            //   enabled: true
            // },
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          plotOptions: {
            pie: {
              offsetX: 0,
            },
          },
          dataLabels: {
            formatter:
       (value, { seriesIndex, dataPointIndex, w }) => {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        })
      },
            style: {
              fontSize: '14px',
            },
          },
          legend: {
            offsetX: 0,
          },
          chart: {
            height: 300,
            width: '100%',
            sparkline: {
              enabled: true
            },
          },
        },
      },
      {
        breakpoint: 1000,
        options: {
          chart: {
            height: 300,
            width: '100%',
            sparkline: {
              enabled: true
            },
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          plotOptions: {
            pie: {
              customScale: 1,
              offsetX: 0,
              offsetY: 0,
            },
          },
          subtitle: {
            margin: 20,
            offsetX: 0,
            offsetY: 40,
          },
          title: {
            margin: 20,
            offsetX: 0,
            offsetY: 0,
          },
          xaxis: {
            labels: {
              show: false
            }
          },
          chart: {
            height: 300,
            width: '100%',
            sparkline: {
              enabled: true
            },
          },
          legend: {
            width: '100%',
            position: 'bottom',
            fontSize: '14px',
            offsetX: -20,
            offsetY: 0,
          },
          dataLabels: {
            formatter:
       (value, { seriesIndex, dataPointIndex, w }) => {
        // return w.config.series[seriesIndex].name + ": " + value
        return  value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        })
      },
            style: {
              fontSize: '14px',
            },
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: 'dark',
      custom: ({ seriesIndex, dataPointIndex, w }): string => {
        return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                  <div class="w-3 h-3 rounded-full" style="background-color: ${
                    w.globals.colors[seriesIndex]
                  };"></div>
                  <div class="ml-2 text-md leading-none">Dia ${
                    w.globals.labels[dataPointIndex]??w.globals.categoryLabels[dataPointIndex]
                  }:</div>
                  <div class="ml-2 text-md font-bold leading-none">${w.globals.series[
                    seriesIndex
                  ][dataPointIndex].toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  })}</div>
            </div>`;
      },
    },
    legend: {
      // position: 'bottom',
      width: 400,
      horizontalAlign: 'left',
      fontSize: '14px',
      offsetX: 0,
      itemMargin: {
        horizontal: 0,
        vertical: 0,
      },
      formatter: (seriesName, opts) => {
        const round = (num, places) => {
          return +parseFloat(num).toFixed(places);
        };
        // const class3w = (index) => {
        //   if (index % 2) {
        //     return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2.5">';
        //   } else {
        //     return '<span >';
        //   }
        // }
        return `<span>${
          opts.w.globals.series[opts.seriesIndex].toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }) +
          ' | ' +
          round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) +
          '%' +
          ' | ' +
          seriesName
        }
                      </span>`;
      },
    },
    title: {
      text: undefined,
      align: 'left',
      margin: 20,
      offsetX: 50,
      offsetY: 0,
      style: {
        fontSize: '25px',
        fontWeight: 'bold',
        fontFamily: 'inherit',
        color: '#303030',
      },
    },
    subtitle: {
      text: undefined,
      // align: 'left',
      margin: 20,
      offsetX: 50,
      // offsetY: 370,
      style: {
        fontSize: '18px',
        fontWeight: 'normal',
        fontFamily: 'inherit',
        color: '#303030',
      },
    },

  };
  dataHeader = this.date.value.format('YYYY-MM-DD');
  Labeshoras = [
    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16' ,'17' ,'18', '19', '20', '21', '22', '23']
  @Debounce(250)
  async getEmpresas(event?) {
    this.dataHeader = this.date.value.format('YYYY-MM-DD');
    this.cdr.detectChanges()
    this.empresas = [];
    this.init_dash = false;

    await this.api
      .post('pesquisarUsuarioEmpresa', {
        idusuario: localStorage.getItem('id'),
      })
      .then(async (response: any) => {
        if (response) {
          let empresas = response.empresas.map((e) => (e = JSON.parse(e)));
          let LABELSDATA = [];
          let date = moment(this.date.value);
          for (
            let index = 1;
            index <= Number(date.endOf('month').format('DD'));
            index++
          ) {
            LABELSDATA.push(index);
          }
          for (let i = 0; i < empresas.length; i++) {
            let vendas_mes = await this.getVendaItemMes(empresas[i].idempresa);
            let vendas_ano = await this.getVendaItemAno(empresas[i].idempresa);
            let vendas_dia = [...vendas_mes];
            vendas_ano = vendas_ano.map((venda) => {
              return {
                ...venda,
                mes: moment(venda.data_emissao).format('MM'),
                dia: moment(venda.data_emissao).format('DD'),
                hora: venda.hora_emissao.substring(0, 2),
              };
            });
            vendas_mes = vendas_mes.map((venda) => {
              return {
                ...venda,
                dia: moment(venda.data_emissao).format('DD'),
                hora: venda.hora_emissao.substring(0, 2),
              };
            });
            vendas_dia = vendas_dia.map((venda) => {
              return {
                ...venda,
                dia: moment(venda.data_emissao).format('DD'),
                hora: venda.hora_emissao.substring(0, 2),
              };
            });
            //Gráfico linear com:
            //^ valor total
            //> Nro de dias do mês
            empresas[i].relatorio_VALOR_MENSAL = vendas_ano;
            empresas[i] = {
              ...empresas[i],
              chartOptions_VALOR_MENSAL: { ...this.chartOptions },
              chartOptions_VALOR_HORA: { ...this.chartOptions },
              chartOptions_VALOR_ANO: { ...this.chartOptions },
            };
            // let element = empresas[i];
            //INICIO GRAFICO TOTAL POR DIA MES

            let VALOR_ANO = [];
            for (let index = 1; index <= 12; index++) {
              VALOR_ANO.push({
                numVendas: vendas_ano
                  .filter((x) => Number(x.mes) == index)
                  .map((y) => Number(y.valor_total))
                  .reduce((a, b) => a + b, 0),
                label: String(index),
              });
            }
            // VALOR_ANO = VALOR_ANO.sort((a: any, b: any) => { return Number(b.numVendas) - Number(a.numVendas) })
            empresas[i].chartOptions_VALOR_ANO.series = [
              {
                name: 'Valor Total xx',
                data: VALOR_ANO.map((e) => Number(e.numVendas)),
              },
            ];
            empresas[i].chartOptions_VALOR_ANO.xaxis = {
              ...empresas[i].chartOptions_VALOR_ANO.xaxis,
              categories: LABELSDATA.map(e=>`Mês ${String(e)}`),
            };
            empresas[i].chartOptions_VALOR_ANO.subtitle = { ...this.chartOptions.subtitle, text: "Valor total por mês no ano" };
            empresas[i].chartOptions_VALOR_ANO.title = { ...this.chartOptions.title,  text:

              VALOR_ANO.map((e) => Number(e.numVendas))
                .reduce((partialSum, a) => partialSum + a, 0)
                .toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })};
            empresas[i].chartOptions_VALOR_ANO = {...empresas[i].chartOptions_VALOR_ANO}
            // FINAL GRAFICO TOTAL POR DIA MES
            //INICIO GRAFICO TOTAL POR DIA MES

            let VALOR_MENSAL = [];
            for (let index = 0; index < LABELSDATA.length; index++) {
              VALOR_MENSAL.push({
                numVendas: vendas_mes
                  .filter((x) => Number(x.dia) == LABELSDATA[index])
                  .map((y) => Number(y.valor_total))
                  .reduce((a, b) => a + b, 0),
                label: String(LABELSDATA[index]),
              });
            }
            // VALOR_MENSAL = VALOR_MENSAL.sort((a: any, b: any) => { return Number(b.numVendas) - Number(a.numVendas) })
            empresas[i].chartOptions_VALOR_MENSAL.series = [
              {
                name: 'Valor Total xx',
                data: VALOR_MENSAL.map((e) => Number(e.numVendas)),
              },
            ];
            empresas[i].chartOptions_VALOR_MENSAL.xaxis = {
              ...empresas[i].chartOptions_VALOR_MENSAL.xaxis,
              categories: LABELSDATA.map(e=>`Dia ${String(e)}`),
            };
            empresas[i].chartOptions_VALOR_MENSAL.subtitle = { ...this.chartOptions.subtitle, text: "Valor total por dia no mês" };
            empresas[i].chartOptions_VALOR_MENSAL.title = { ...this.chartOptions.title,  text:

              VALOR_MENSAL.map((e) => Number(e.numVendas))
                .reduce((partialSum, a) => partialSum + a, 0)
                .toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })};
            empresas[i].chartOptions_VALOR_MENSAL = {...empresas[i].chartOptions_VALOR_MENSAL}
            // FINAL GRAFICO TOTAL POR DIA MES

            // INICIO GRAFTI TOTAL POR HORA NO DIA
            // let DASHFORMATADA = [];

            // for (let index = 0; index < this.Labeshoras.length; index++) {
            //   DASHFORMATADA.push({
            //       descricao: this.Labeshoras[index],
            //       valor_total: vendas_dia
            //       .filter((x) => x.hora == this.Labeshoras[index])
            //       .map((y) => Number(y.valor_total))
            //       .reduce((a, b) => a + b, 0),
            //   })
            // }
            let vendas_dia_grupo_hora = _.groupBy(vendas_dia, 'hora');
            let DASHFORMATADA = [];
            let keys = Object.keys(vendas_dia_grupo_hora);

            for (let index = 0; index < keys.length; index++) {
              const key = keys[index];
              DASHFORMATADA.push({
                  descricao: key,
                  valor_total: vendas_dia_grupo_hora[key].map(e=> Number(e.valor_total)).reduce((b, a) => b + a, 0),
              })
            }
              // if(!vendas_dia_grupo_hora['00'] && vendas_dia_grupo_hora) {
              //   DASHFORMATADA.unshift({
              //     descricao: "00",
              //     valor_total : 0
              //   })
              // }
              DASHFORMATADA.sort( (a, b) => (Number(a.descricao) - b.descricao))
              empresas[i].chartOptions_VALOR_HORA.series = [
                {
                  name: 'Valor Total',
                  data: DASHFORMATADA.map((e) => Number(e.valor_total)),
                },
              ];
              empresas[i].chartOptions_VALOR_HORA.xaxis = {
                ...empresas[i].chartOptions_VALOR_HORA.xaxis,
                categories: DASHFORMATADA.map(e=>`${String(e.descricao)} Horas`),
              };

              empresas[i].chartOptions_VALOR_HORA.tooltip = { ...empresas[i].chartOptions_VALOR_HORA.tooltip,

                custom: ({ seriesIndex, dataPointIndex, w }): string => {
                  return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                            <div class="w-3 h-3 rounded-full" style="background-color: ${
                              w.globals.colors[seriesIndex]
                            };"></div>
                            <div class="ml-2 text-md leading-none">${
                              w.globals.categoryLabels[dataPointIndex]??w.globals.labels[dataPointIndex]
                            }:</div>
                            <div class="ml-2 text-md font-bold leading-none">${w.globals.series[
                              seriesIndex
                            ][dataPointIndex].toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              style: 'currency',
                              currency: 'BRL',
                            })}</div>
                      </div>`;
                }
              }
              empresas[i].chartOptions_VALOR_HORA.subtitle = { ...this.chartOptions.subtitle,  text:
                'Valor total por Hora'};;

              let titleVALOR_HORA = { ...this.chartOptions.title, text:
                DASHFORMATADA.map((e) => Number(e.valor_total))
                  .reduce((partialSum, a) => partialSum + a, 0)
                  .toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }) };
              empresas[i].chartOptions_VALOR_HORA.title=titleVALOR_HORA;
            empresas[i].chartOptions_VALOR_HORA = {...empresas[i].chartOptions_VALOR_HORA}

          this.cdr.detectChanges();
          }
          this.empresas = empresas;
          this.cdr.detectChanges();
          //VALOR_MENSAL

        }
      })
      .finally(() => {
        setTimeout(() => {
          this.init_dash = true;
          this.cdr.detectChanges();
          event?.target.complete();
        }, 200);
      });
  }

  async getVendaItemMes(empresa_id) {
    let date = moment(this.date.value);
    return await this.api.get('venda-item/listar', {
      empresa_csw: empresa_id,
      data_inicio: date.startOf('month').format('YYYY-MM-DD'),
      data_fim: date.endOf('month').format('YYYY-MM-DD'),
    });
  }
  async getVendaItemAno(empresa_id) {
    let date = moment(this.date.value);
    return await this.api.get('venda-item/listar', {
      empresa_csw: empresa_id,
      data_inicio: date.startOf('year').format('YYYY-MM-DD'),
      data_fim: date.endOf('year').format('YYYY-MM-DD'),
    });
  }
  async getVendaItemDia(empresa_id) {
    let date = moment(this.date.value);
    return await this.api.get('venda-item/listar', {
      empresa_csw: empresa_id,
      data_inicio: date.format('YYYY-MM-DD'),
      data_fim: date.format('YYYY-MM-DD'),
    });
  }

  // setMonthAndYear(
  //   normalizedMonthAndYear: Moment,
  //   datepicker: MatDatepicker<Moment>
  // ) {
  //   const ctrlValue = this.date.value!;
  //   ctrlValue.month(normalizedMonthAndYear.month());
  //   ctrlValue.year(normalizedMonthAndYear.year());
  //   ctrlValue.day(normalizedMonthAndYear.day());
  //   this.date.setValue(moment(ctrlValue));
  //   this.getEmpresas();
  //   // datepicker.close();
  //   this.cdr.markForCheck();
  //   this.cdr.detectChanges();
  // }
}
